:root {
    --glow-color: rgba(52, 152, 219);
}

.sidebar-dropdown .dropdown-menu {
    width: 100%;
    transform: translate3d(0, 50px, 0px) !important;
    border-radius: 0;
}

.dropdown-menu .dropdown-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.dropdown-menu .dropdown-item:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.uppercase {
    text-transform: uppercase;
}

* {
    text-transform: capitalize;
}

input {
    text-transform: none;
}

.description {
    color: #ffffff;
    font-weight: 500;
}

tr>td {
    font-size: 15px;
    text-transform: uppercase;
}

.lottery-card .icon {
    font-size: 2rem;
    background: #1d1d1d;
    display: inline-block;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    height: 50px;
    width: 50px;
}

.gametypeList {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
    justify-content: center;
}

.lottery-card h4 {
    color: #ffffff;
}

h1 {
    color: #f89928;
}

.gametypeList .gametypeCard {
    width: 150px;
    height: 150px;
    border: 3px solid #f89928;
    margin: 0 5px 10px;
    border-radius: 5px;
    background-image: radial-gradient(#fbf09b, #eec253);
    text-align: center;
    padding: 15px;
    color: #f89928;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.gametypeList .gametypeCard span {
    font-size: 16px;
    line-height: 1.7;
    padding: 5px 0;
    border-bottom: 2px dotted #6d3b32;
    min-width: 50px;
    color: #6d3b32;
    margin-bottom: 10px;
}

.gametypeList .gametypeCard span.game-rate {
    font-size: 13px;
    color: #6d3b32;
}

.gametypeList .gametypeCard span:last-child {
    border-bottom: 0;
}

.gametypeList .gametypeCard:hover a {
    text-decoration: none;
    color: #eec253;
}

.gametypeList .gametypeCard:hover span,
.gametypeList .gametypeCard:hover span.game-rate {
    color: #eec253;
}

table {
    overflow: scroll;
    padding-bottom: 10px;
}

.gametypeList .gametypeCard svg {
    display: block;
    margin: 0 auto 5px;
    width: 72.5px;
    margin-top: 2px;
    max-width: 100%;
    max-height: 60px;
    fill: #6d3b32;
}

.gametypeList .gametypeCard:hover {
    border: 3px solid #6d3b32;
    background: #6d3b32;
}

.gametypeList .gametypeCard:hover svg {
    fill: #eec253;
}

/* [class*="bg_pattern__shape-"] {
    position: absolute;
    border-radius: 50%;
    -webkit-filter: blur(1px);
    filter: blur(1px);
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
.bg_pattern__shape-1 {
    background-image: linear-gradient(40deg, #999 0%, #ccc 100%);
    width: 80px;
    height: 80px;
    top: 5%;
    left: 3%;
    -webkit-animation-duration: 8s;
    animation-duration: 8s;
    -webkit-perspective: 100px;
    perspective: 100px;
}
.bg_pattern__shape-2 {
    background-image: linear-gradient(40deg, #999 0%, #ccc 100%);
    width: 75px;
    height: 75px;
    top: 12%;
    left: 37%;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-perspective: 75px;
    perspective: 75px;
}
.bg_pattern__shape-3 {
    background-image: linear-gradient(40deg, #67717b 0%, #50bad7 100%);
    width: 75px;
    height: 75px;
    bottom: 12%;
    left: 6%;
    -webkit-animation-duration: 7s;
    animation-duration: 7s;
    -webkit-perspective: 75px;
    perspective: 75px;
}
.bg_pattern__shape-4 {
    background-image: linear-gradient(40deg, #67717b 0%, #50bad7 100%);
    width: 75px;
    height: 75px;
    top: 20%;
    right: 4%;
    -webkit-animation-duration: 12s;
    animation-duration: 12s;
    -webkit-perspective: 75px;
    perspective: 75px;
}
.bg_pattern__shape-5 {
    background-image: linear-gradient(40deg, #999 0%, #ccc 100%);
    width: 75px;
    height: 75px;
    bottom: 12%;
    right: 6%;
    -webkit-animation-duration: 7s;
    animation-duration: 7s;
    -webkit-perspective: 75px;
    perspective: 75px;
}
@-webkit-keyframes bubbleMover {
    0% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0);
        transform: translateY(0px) translateX(0) rotate(0);
    }
    30% {
        -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
        transform: translateY(30px) translateX(50px) rotate(15deg);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }
    50% {
        -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
        transform: translateY(50px) translateX(100px) rotate(45deg);
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
    }
    80% {
        -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
        transform: translateY(30px) translateX(50px) rotate(15deg);
        -webkit-transform-origin: left top;
        transform-origin: left top;
    }
    100% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0);
        transform: translateY(0px) translateX(0) rotate(0);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }
}
@keyframes bubbleMover {
    0% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0);
        transform: translateY(0px) translateX(0) rotate(0);
    }
    30% {
        -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
        transform: translateY(30px) translateX(50px) rotate(15deg);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }
    50% {
        -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
        transform: translateY(50px) translateX(100px) rotate(45deg);
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
    }
    80% {
        -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
        transform: translateY(30px) translateX(50px) rotate(15deg);
        -webkit-transform-origin: left top;
        transform-origin: left top;
    }
    100% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0);
        transform: translateY(0px) translateX(0) rotate(0);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }
} */
.cardHeaderBottomBorder {
    border-bottom: 1px solid #1d1d1d;
    border-bottom-left-radius: 15px;
    background: #1d1d1d;
    color: #fff;
    overflow: hidden;
}

.wrapper.auth {
    background-image: linear-gradient(to left, #d50201 -11%, #000000 100%);
    background: url("../img/9.jpeg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    background-attachment: fixed;
}

a {
    min-width: max-content;
}

.auth-card {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    background: transparent !important;
    border-radius: 0;
    top: 0;
    border: none;
    margin: 0 auto;
    color: #ffffff !important;
}

.auth-card .auth-card-caption {
    background-color: rgba(0, 0, 0, 0.72);
    font-weight: bold;
    border: 3px solid transparent;
    z-index: 2;
    font-size: 17px;
}

.logo-header {
    /* position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%,-50%); */
    max-width: 100%;
}

.theme-btn {
    border-radius: 0;
    font-size: 16px;
    background: #1d1d1d !important;
    /* border: 1px solid #1d1d1d; */
    /* color: #1d1d1d; */
}

.theme-btn:hover,
.btn.theme-btn:hover {
    background: #f7df08 !important;
    color: #000000 !important;
    font-size: 16px;
}

.text-light {
    color: #f7df08 !important;
}

.theme-btn .nav-link {
    background-color: transparent;
    border-radius: 0;
    transition: all 0.5s ease-in-out;
}

.theme-btn .nav-link.active {
    background: #f7df08 !important;
}

.text-danger,
a.text-danger:focus,
a.text-danger:hover {
    color: #fd0300 !important;
}

.delete-btn {
    padding: 2px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -50%;
}

.input-style,
.input-style:focus,
.input-style:active {
    background: transparent;
    border: none;
    border-bottom: 1px solid;
    color: #000000;
    border-radius: 0;
}

::placeholder {
    color: rgba(255, 255, 255, 0.7) !important;
}

.btn {
    border-radius: 0;
}

.form-control {
    border-radius: 0;
}

.card {
    border-radius: 0;
}

.card .card-header {
    padding: 10px;
    border: 0;
    background-color: #e0af30 !important;
}

.card .card-header .card-title {
    font-weight: 700;
}

.card.dashboard-card {
    background: radial-gradient(#af8c27, #a64c25);
}

.card.lottery-card {
    background: radial-gradient(#af8c27, #a64c25);
    margin-bottom: 5rem;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.navbar .navbar-wrapper{
    width: 100%;
}
.card-icon {
    border: 4px solid #ffffff;
    padding: 10px;
    border-radius: 50%;
    font-size: 30px;
    color: #ffffff;
}

.card-link {
    text-align: center;
    display: block;
    width: 100%;
    padding: 10px 0;
    margin: 0;
    /* border-top: 1px solid; */
    background: #1d1d1d !important;
    font-weight: 800;
    color: #ffffff;
}

.text-success,
a.text-success:focus,
a.text-success:hover {
    color: #74f2ac !important;
}

.navbar .navbar-brand {
    color: #ffffff !important;
}

.card-detail {
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
}

.bg-dark {
    background: #1d1d1d !important;
    color: #ffffff;
}

.bg-yellow {
    background: #f7df08 !important;
    color: #000 !important;
}
.bg-skyblue {
    background: #46a1ff !important;
    color: #000 !important;
}

.navbar a:not(.btn):not(.dropdown-item) {
    color: #000000;
}

/* .pro-sidebar > .pro-sidebar-inner {
    background: #d1cbca !important;
} */
.main-panel {
    background: #1d1d1de0;
}

.pro-sidebar .pro-menu {
    padding-top: 0;
}

a.card-link:hover,
a.card-link:focus {
    color: #ffffff;
}

h2 {
    margin-bottom: 0;
}

.pro-sidebar .pro-menu a.nav-link {
    text-decoration: none;
    color: #ffffff;
    font-weight: 800;
    font-size: 15px;
    padding: 12px;
}

.sidebar .logo,
.off-canvas-sidebar .logo {
    z-index: 4;
    height: 120px;
    width: 100%;
    object-fit: contain;
}

.pro-sidebar .pro-menu .pro-menu-item {
    padding: 0;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
    padding: 0 !important;
}

.pro-sidebar .pro-menu a.nav-link.active {
    background: #af8c27;
    background-size: cover;
}

.user-name {
    text-decoration: none;
    color: #ffffff;
    font-weight: 800;
    font-size: 20px;
    background: #0200fe;
}

@media screen and (max-width: 720px) {
    .auth-card .auth-card-caption {
        /* width: 100%;
            position: absolute; */
        top: 0;
        transform: translate(0, 0);
        left: 0;
        bottom: 0;
        font-size: 17px;
    }

    .auth-card {
        position: relative;
        height: 100%;
        min-height: 100vh;
        transform: none;
        right: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 0;
        top: 0;
    }

    .wrapper.auth {
        background-position: center;
    }

    .mobile-card {
        width: 100%;
        border-radius: 12px;
        box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
        background-color: #ffffff;
        color: #252422;
        margin-bottom: 20px;
        position: relative;
        border: 1px solid #000000;
        transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
    }
}

::-webkit-scrollbar {
    width: 1px;
}

iframe {
    min-height: 900px;
}

.game-radio {
    text-align: center;
    padding: 15px;
    font-size: 25px !important;
    font-weight: 600;
    border: 3px solid transparent;
}

.input-radio-off {
    opacity: 0;
    width: 0;
}

input:checked+.game-radio {
    border: 3px solid #1d1d1d;
    color: #1d1d1d;
}

.checkmark {
    opacity: 0;
    width: 0;
}

.input-radio-off:checked+.game-radio .checkmark {
    opacity: 1;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 35%;
    transform: translate(-35%, -50%);
    width: 30px;
    height: 30px;
    line-height: 1;
    background-color: transparent;
    border: 3px solid #1d1d1d;
    border-radius: 50%;
}

.input-radio-off:checked+.game-radio .checkmark:after {
    content: "";
    opacity: 1;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    line-height: 1;
    background-color: #1d1d1d;
    border: 3px solid #1d1d1d;
    border-radius: 50%;
}

.profitCard {
    display: flex;
    width: 100%;
    list-style: none;
    padding-left: 0;
    margin-left: 0;
    overflow-y: scroll;
    flex-wrap: wrap;
    height: fit-content;
}

.profitCard::-webkit-scrollbar {
    width: 5px;
}

.profitCard::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.profitCard::-webkit-scrollbar-thumb {
    background: #1d1d1d;
    border-radius: 10px;
}

.profitCard li {
    min-width: max-content;
    border: 1px solid;
    padding: 5px 4px;
    font-size: 0.9rem;
    margin-left: 5px;
    margin-bottom: 5px;
    background-color: #bee5eb;
    color: #414040;
}

.profitCard li:nth-child(2n) {
    background-color: #efaa00;
}

 .pro-sidebar > .pro-sidebar-inner {
    background-image: url("../img/sidebar-bg.jpg") !important;
    position: relative;
 }
 .pro-sidebar > .pro-sidebar-inner::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.7);

 }
/* .pro-sidebar > .pro-sidebar-inner {
    background-color: #750609 !important;
    background-image: url(https://static8.depositphotos.com/1013869/934/v/600/depositphotos_9341661-stock-illustration-poker-background-vector-illustration.jpg) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
} */
.card label {
    font-size: 0.8571em;
    margin-bottom: 5px;
    color: #170505;
    font-weight: 600;
}

.preloader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
    background: radial-gradient(#d50201, #000000);

    /* background:linear-gradient(to left, #D50201 -11%, #000000 100%); */
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 800;
    font-size: 2rem;
    text-align: center;
}

.main-panel{
    background-image: url("../img/dice-bg.jpg");
    background-size:cover;
    position: relative;
}
/* .main-card::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.9);
    height: 100%;
    width: 100%;
} */

.content .card{
    background-color: rgba(255, 255, 255, 0.85);
}

.navbar-brand{
    width: 110px !important;
    text-align: center;
    min-width: auto;
}

.preloader span {
    position: relative;
}

.preloader span.sr-only {
    opacity: 0;
}

.preloader span:before {
    content: attr(data-text);
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 0%;
    display: flex;
}

.preloader.animate span:before {
    transition: 1s;
    width: 100%;
    color: #f7df08;
}

.preloader.animate {
    opacity: 0;
    transition: 2.5s;
}

.preloader .text-primary,
a.text-primary:focus,
a.text-primary:hover {
    color: #f7df08 !important;
}
.navbar p{
    line-height: 1.2em;
}

.digit-selection-arr {
    display: flex;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    flex-wrap: wrap;
    max-height: max-content;
    overflow: auto;
}

.submit-btn-spninner span div {
    width: 1rem !important;
    height: 1rem !important;
}

.digit-selection-arr li {
    margin-left: 15px;
    margin-bottom: 10px;
}

.digit-selection-arr li input {
    width: 0;
    opacity: 0;
}

.digit-selection-arr li input+label {
    width: 50px;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 5px;
    text-align: center;
}

.digit-selection-arr li input:checked+label {
    border: 1px solid #750609;
    background-color: #750609;
    color: #fff;
}

.footer-contact-icon {
    position: absolute;
    right: 15px;
    width: 50px;
    height: 50px;
    bottom: 50px;
}

.footer-contact-icon img {
    position: absolute;
    width: 50px;
    height: 50px;
}

.text-green {
    color: green;
    font-weight: 600;
}

.text-red {
    color: #b20000;
    font-weight: 600;
}

.bg-blue {
    background: #0200fe !important;
    color: #ffffff !important;
}

@media screen and (max-width: 991px) {

    .sidebar,
    .bootstrap-navbar {
        -webkit-transform: translate3d(-271px, 0, 0);
        transform: translate3d(-271px, 0, 0);
    }
}

@media only screen and (max-width: 720px) {
    .main-panel .content {
        padding-top: 15px;
    }

    .input-radio-off:checked+.game-radio .checkmark {
        left: 15%;
        transform: translate(-15%, -50%);
    }

    tr>td {
        font-size: 13px;
    }

    .navbar .navbar-toggler-bar {
        background-color: #ffffff;
    }

    h1 {
        font-size: 1.5em;
    }

    .navbar.navbar-absolute {
        padding: 5px 0;
    }
}

.lang-select {
    background-color: transparent;
    padding: 10px 15px;
    color: #fff;
    border-color: #fff;
}

.lang-select option {
    background-color: #1d1d1d;
    padding: 10px 15px;
    color: #fff;
    border-color: #fff;
}


.live {
    animation: liveBet 2s infinite ease-in;
    margin: 5px;
}

.breadcrumb {
    background-color: #fadf76;
}

.breadcrumb-item.active {
    color: #000000;
    font-weight: 700;
}

h3,
.h3 {
    margin-bottom: 5px;
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #0200fe;
    color: #ffffff;
}

.lottery-btn {
    border-radius: 30px;
    position: absolute;
    left: 50%;
    bottom: -18%;
    -webkit-transform: translate(-50%, -30%);
    transform: translate(-50%, -30%);
    border: 3px solid #fff;
    background-color: #1e1486 !important;
}
.lottery-btn h6{
    margin-bottom: 0;
}

.closed {
    position: absolute;
    right: 0;
    color: #ff0000;
    top: 39px;
    font-weight: 600;
    width: 100%;
}

.running {
    position: absolute;
    left: 0;
    color: #43A047;
    top: 39px;
    font-weight: 600;
    width: 100%;
}
.game-time-btn{
    background-color: transparent;
    border: 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.game-time-btn img{
    width: 50px;
}

@keyframes liveBet {
    0% {
        color: #ff0000;
        font-size: 16px;
        font-weight: 800;
        text-shadow: 0 2px 4px lime;
    }

    66% {
        color: lime;
        font-size: 26px;
        font-weight: 400;
        text-shadow: 0 2px 4px red;
    }

    100% {
        color: red;
        font-size: 16px;
        font-weight: 200;
    }
}

.bg-blue-icon {
    background-image: url("../img/l-bg.jpeg") !important;
    background-repeat: no-repeat !important;
    background-size: 100% !important;

}

.lottery-icon {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    overflow: hidden;
    height: 75px;
    width: 75px;
    border: 3px solid #ffffff;
    display: flex;
    align-items: center;
    background: #0200fe;
    background-image: url("../img/l-bg.jpeg");
}

.lottery-icon img {
    padding: 10px;
}

#play {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    width: 54px;
    height: 54px;
    background: linear-gradient(135deg, #ea5068 0%, #8161f5 50%, #12d4e4 100%);
    border-radius: 50%;
    position: relative;
    border: 2px solid #fff;
    box-shadow: 5px 5px 7px -2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    margin: 0 auto;
  }
  #play::after {
    display: block;
    content: "";
    position: absolute;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: background 1s ease;
  }
  
  #me {
    position: relative;
    z-index: 1;
    height: 22px;
    width: 4px;
    background: white;
    border-radius: 3px;
    margin: 14px 20px 0 18px;
    left: 0;
    transition: background 0.2s ease;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  #me::before, #me::after {
    display: block;
    content: "";
    position: absolute;
    height: 20px;
    width: 4px;
    background: inherit;
    border-radius: 3px;
    left: 15px;
    transition: all 0.5s ease;
  }
  #me::before {
    top: 11px;
    transform: rotate(56deg);
    transform-origin: top center;
  }
  #me::after {
    top: -8px;
    transform: rotate(-56deg);
    transform-origin: bottom center;
  }